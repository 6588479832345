import React from "react"
import styled from "styled-components"
import { down } from "styled-breakpoints"
import { StaticImage } from "gatsby-plugin-image"
//DATA
//STYLES
import { H2, H5, LabelBW, Text } from "../utils/typo"
import { ContainerMob, Section2 } from "../utils/utils"
//COMPONENTS
import Seo from "../components/seo/SEO"

const InfoWrapper = styled.div``

const Dzialanie = () => {
  return (
    <div>
      <Seo title="Projekt" slug="/projekt" />

      <Section2
        pt={6}
        pb={6}
        tabIndex="0"
        role="contentinfo"
        aria-label="Informacje o działaniu systemu Therm Check"
      >
       <ContainerMob>
            <LabelBW mb="2rem">Projekt badawczo rozwojowy</LabelBW>
            <H2 mb="1rem" lineHeight="1.1">
            Tytuł projektu: Miniaturyzacja oraz rozbudowa systemu bezdotykowego pomiaru temperatury.
            </H2>
            <Text weight="700">
              — Projekt realizowany jest w ramach Poddziałania 1.1.1 Programu Operacyjnego Inteligentny Rozwój 2014-2020 współfinansowanego ze środków Europejskiego Funduszu Rozwoju Regionalnego
            </Text>
            <H5 mt="4rem">1. Cel projektu</H5>
            <Text mt="2rem">
            Celem projektu jest znaczące ulepszenie istniejącego ofercie firmy rozwiązania, pozwalającego na szybki i bezdotykowy pomiar temperatury ciała z powierzchni oka. Ulepszenie te będzie polegało na miniaturyzacji komponentów zestawu oraz na opracowaniu innowacyjnego algorytmu decyzyjnego, który będzie mógł wykrywać osoby o potencjalnie podwyższonej temperaturze z wykorzystaniem informacji o szacowanym wieku oraz tętnie mierzonej osoby (układ dwustanowy wskazujący na temp. normalną lub podwyższoną).
            </Text>
            <H5 mt="4rem">2. Planowane efekty</H5>
            <Text mt="2rem">
            Rezultatem projektu będzie zminiaturyzowana jednostka centralna oraz algorytm decyzyjny, uwzględniający wiek oraz tętno przy podejmowaniu decyzji o kwalifikacji jako osoba z podwyższoną temperaturą. Rezultaty zostaną wykorzystane w prowadzonej działalności gospodarczej poprzez wdrożenie do oferty zestawu ze zminiaturyzowaną wersją jednostki centralnej (co w znaczący sposób ułatwi wdrożenie u klienta oraz może potencjalnie obniżyć koszt wytworzenia zestawu). Dodatkowo dzięki wykorzystaniu opracowanego algorytmu, podniesiona zostanie czułość i specyficzność całego rozwiązania, co podniesie konkurencyjność naszego produktu.
            </Text>
            <Text mt="2rem">
            Uzyskane w wyniku przeprowadzenia projektu rezultaty będą stanowiły znaczące ulepszenie istniejącego rozwiązania. Dzięki opracowanym usprawnieniom, możliwe będzie wykonywanie pomiaru temperatury i określenie, czy dana osoba ma podwyższoną temperaturę w sposób bezdotykowy i relatywnie szybki ( szczególnie w porównaniu do tradycyjnego pomiaru przy wykorzystaniu termometru pirometrycznego) Tego typu systemy mogą być szeroko wykorzystywane w instytucjach, w których następuje duża rotacja osób, na przykład uczelnie wyższe, szkoły, instytucje publiczne, urzędy, placówki pocztowe, fabryki, szpitale czy przychodnie.
            </Text>
            <H5 mt="4rem">3. Wartość projektu</H5>
            <Text mt="2rem">
            Wartość projektu: 1 140 250,00 zł
            </Text>
            <H5 mt="4rem">4. Wkład Funduszy Europejskich</H5>
            <Text mt="2rem">
            Wartość dofinansowania: 732 150,00 zł
            </Text>
        

      </ContainerMob> 
        
      </Section2>
    </div>
  )
}
export default Dzialanie
